var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    {
      staticClass: "site-content site-content--center page",
      attrs: { id: "wrapper" }
    },
    [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          {
            staticClass:
              "page-heading page-heading--default text-small text-center w-100 mt-5"
          },
          [
            _c(
              "h3",
              { staticClass: "page-heading__title h2 mb-2 color-primary" },
              [_vm._v(" YOUR CHALLENGES ")]
            ),
            _c("div", { staticClass: "page-heading__subtitle h5" }, [
              _c("span", [_vm._v("CURRENTLY VIEWING: ")]),
              _c("span", { staticClass: "color-primary" }, [
                _vm._v(_vm._s(_vm.challengeType))
              ])
            ])
          ]
        ),
        _c("ul", { staticClass: "match-stats-links match-stats-links--main" }, [
          _c(
            "li",
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: { name: "ChallengeType", params: { type: "all" } },
                    "active-class": "active"
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "df-icon df-icon--match-stats",
                      attrs: { role: "img" }
                    },
                    [
                      _c("use", {
                        attrs: {
                          "xlink:href":
                            require("@/assets/theme/img/necromancers.svg") +
                            "#match-stats"
                        }
                      })
                    ]
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "ChallengeType",
                      params: { type: "active" }
                    },
                    "active-class": "active"
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "df-icon df-icon--explosion",
                      attrs: { role: "img" }
                    },
                    [
                      _c("use", {
                        attrs: {
                          "xlink:href":
                            require("@/assets/theme/img/necromancers.svg") +
                            "#explosion"
                        }
                      })
                    ]
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "ChallengeType",
                      params: { type: "sent" }
                    },
                    "active-class": "active"
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "df-icon df-icon--offense",
                      attrs: { role: "img" }
                    },
                    [
                      _c("use", {
                        attrs: {
                          "xlink:href":
                            require("@/assets/theme/img/necromancers.svg") +
                            "#offense"
                        }
                      })
                    ]
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "ChallengeType",
                      params: { type: "received" }
                    },
                    "active-class": "active"
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "df-icon df-icon--defense",
                      attrs: { role: "img" }
                    },
                    [
                      _c("use", {
                        attrs: {
                          "xlink:href":
                            require("@/assets/theme/img/necromancers.svg") +
                            "#defense"
                        }
                      })
                    ]
                  )
                ]
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "matches-tabs mt-sm-auto mb-sm-auto" }, [
          _c("div", { staticClass: "matches-tabs__content tab-content" }, [
            _c(
              "div",
              {
                staticClass:
                  "match-lineups-container tab-pane fade show active",
                attrs: { id: "tab-1", role: "tabpanel" }
              },
              [
                _c("b-table", {
                  staticClass:
                    "matches-table lineups-table lineups-table--style-1",
                  staticStyle: { cursor: "pointer" },
                  attrs: {
                    id: "challengeTable",
                    items: _vm.items,
                    fields: _vm.fields,
                    busy: _vm.isBusy,
                    "show-empty": "",
                    "per-page": _vm.perPage,
                    "current-page": _vm.currentPage
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "cell(challenger)",
                      fn: function(data) {
                        return [
                          _c(
                            "figure",
                            {
                              staticClass: "match-player team-1",
                              attrs: { role: "group" }
                            },
                            [
                              _c(
                                "figure",
                                { staticClass: "match-player__avatar" },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: data.item.players[0].avatar,
                                      srcset: data.item.players[0].avatar,
                                      alt: ""
                                    }
                                  })
                                ]
                              ),
                              _c("figcaption", { staticClass: "ml-2" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "match-player__nickname",
                                    staticStyle: { "font-size": "1.2rem" }
                                  },
                                  [_vm._v(_vm._s(data.item.players[0].name))]
                                ),
                                data.item.players[0].character
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "match-player__name",
                                        staticStyle: { "font-size": "0.8rem" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            data.item.players[0].character.name
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            ]
                          )
                        ]
                      }
                    },
                    {
                      key: "cell(challengerResult)",
                      fn: function() {
                        return [
                          _c(
                            "svg",
                            {
                              staticClass: "df-icon df-icon--medal",
                              attrs: { role: "img" }
                            },
                            [
                              _c("use", {
                                attrs: {
                                  "xlink:href":
                                    require("@/assets/theme/img/necromancers.svg") +
                                    "#medal"
                                }
                              })
                            ]
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "cell(actions)",
                      fn: function(data) {
                        return [
                          _vm.challengeType === "active"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { variant: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.goToActiveChallenge(data)
                                        }
                                      }
                                    },
                                    [_c("span", [_vm._v("GO NOW")])]
                                  )
                                ],
                                1
                              )
                            : data.item.gameMatchId
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { variant: "secondary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.viewChallengeDetails(data)
                                        }
                                      }
                                    },
                                    [_c("span", [_vm._v("DETAILS")])]
                                  )
                                ],
                                1
                              )
                            : _c("div", [_c("span", [_vm._v("EXPIRED")])])
                        ]
                      }
                    },
                    {
                      key: "cell(challengedResult)",
                      fn: function() {
                        return [
                          _c(
                            "svg",
                            {
                              staticClass: "df-icon df-icon--skull",
                              attrs: { role: "img" }
                            },
                            [
                              _c("use", {
                                attrs: {
                                  "xlink:href":
                                    require("@/assets/theme/img/necromancers.svg") +
                                    "#skull"
                                }
                              })
                            ]
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "cell(challenged)",
                      fn: function(data) {
                        return [
                          _c(
                            "figure",
                            {
                              staticClass: "match-player team-2",
                              staticStyle: { "justify-content": "flex-end" },
                              attrs: { role: "group" }
                            },
                            [
                              _c(
                                "figure",
                                {
                                  staticClass: "match-player__avatar",
                                  staticStyle: { order: "2" }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: data.item.players[1].avatar,
                                      srcset: data.item.players[1].avatar,
                                      alt: ""
                                    }
                                  })
                                ]
                              ),
                              _c("figcaption", { staticClass: "mr-2" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "match-player__nickname",
                                    staticStyle: { "font-size": "1.2rem" }
                                  },
                                  [_vm._v(_vm._s(data.item.players[1].name))]
                                ),
                                data.item.players[0].character
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "match-player__name",
                                        staticStyle: { "font-size": "0.8rem" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            data.item.players[1].character.name
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            ]
                          )
                        ]
                      }
                    },
                    {
                      key: "empty",
                      fn: function() {
                        return [
                          _c(
                            "h5",
                            {
                              staticClass: "color-primary text-center my-auto"
                            },
                            [_vm._v(" NO RECORDS FOUND ")]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                })
              ],
              1
            )
          ])
        ]),
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("b-pagination", {
              attrs: {
                "total-rows": _vm.rows,
                "per-page": _vm.perPage,
                size: "lg",
                align: "center"
              },
              model: {
                value: _vm.currentPage,
                callback: function($$v) {
                  _vm.currentPage = $$v
                },
                expression: "currentPage"
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }