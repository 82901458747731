<template>
	<main class="site-content site-content--center page" id="wrapper">
		<div class="container">
			<div
				class="page-heading page-heading--default text-small text-center w-100 mt-5"
			>
				<h3 class="page-heading__title h2 mb-2 color-primary">
					YOUR CHALLENGES
				</h3>
				<div class="page-heading__subtitle h5">
					<span>CURRENTLY VIEWING: </span>
					<span class="color-primary">{{ challengeType }}</span>
				</div>
			</div>
			<ul class="match-stats-links match-stats-links--main">
				<li>
					<router-link
						:to="{ name: 'ChallengeType', params: { type: 'all' } }"
						active-class="active"
					>
						<svg role="img" class="df-icon df-icon--match-stats">
							<use
								xlink:href="@/assets/theme/img/necromancers.svg#match-stats"
							/>
						</svg>
					</router-link>
				</li>
				<li>
					<router-link
						:to="{
							name: 'ChallengeType',
							params: { type: 'active' },
						}"
						active-class="active"
					>
						<svg role="img" class="df-icon df-icon--explosion">
							<use
								xlink:href="@/assets/theme/img/necromancers.svg#explosion"
							/>
						</svg>
					</router-link>
				</li>
				<li>
					<router-link
						:to="{
							name: 'ChallengeType',
							params: { type: 'sent' },
						}"
						active-class="active"
					>
						<svg role="img" class="df-icon df-icon--offense">
							<use
								xlink:href="@/assets/theme/img/necromancers.svg#offense"
							/>
						</svg>
					</router-link>
				</li>
				<li>
					<router-link
						:to="{
							name: 'ChallengeType',
							params: { type: 'received' },
						}"
						active-class="active"
					>
						<svg role="img" class="df-icon df-icon--defense">
							<use
								xlink:href="@/assets/theme/img/necromancers.svg#defense"
							/>
						</svg>
					</router-link>
				</li>
			</ul>
			<div class="matches-tabs mt-sm-auto mb-sm-auto">
				<div class="matches-tabs__content tab-content">
					<div
						class="match-lineups-container tab-pane fade show active"
						id="tab-1"
						role="tabpanel"
					>
						<b-table
							id="challengeTable"
							:items="items"
							:fields="fields"
							:busy="isBusy"
							show-empty
							:per-page="perPage"
							:current-page="currentPage"
							class="matches-table lineups-table lineups-table--style-1"
							style="cursor: pointer"
						>
							<template v-slot:cell(challenger)="data">
								<figure
									class="match-player team-1"
									role="group"
								>
									<figure class="match-player__avatar">
										<img
											:src="
												data.item.players[0].avatar
											"
											:srcset="
												data.item.players[0].avatar
											"
											alt=""
										/>
									</figure>
									<figcaption class="ml-2">
										<span
											class="match-player__nickname"
											style="font-size: 1.2rem"
											>{{
												data.item.players[0].name
											}}</span
										>
										<span v-if="data.item.players[0].character"
											class="match-player__name"
											style="font-size: 0.8rem"
											>{{
												data.item.players[0].character.name
											}}</span
										>
									</figcaption>
								</figure>
							</template>
							<template v-slot:cell(challengerResult)>
								<svg role="img" class="df-icon df-icon--medal">
									<use
										xlink:href="@/assets/theme/img/necromancers.svg#medal"
									/>
								</svg>
							</template>

							<template v-slot:cell(actions)="data">
								<div v-if="challengeType === 'active'">
									<b-button
										variant="primary"
										@click="goToActiveChallenge(data)"
									>
										<span>GO NOW</span>
									</b-button>
								</div>
								<div v-else-if="data.item.gameMatchId">
									<b-button
										variant="secondary"
										@click="viewChallengeDetails(data)"
									>
										<span>DETAILS</span>
									</b-button>
								</div>
								<div v-else>
									<span>EXPIRED</span>
								</div>
							</template>

							<template v-slot:cell(challengedResult)>
								<svg role="img" class="df-icon df-icon--skull">
									<use
										xlink:href="@/assets/theme/img/necromancers.svg#skull"
									/>
								</svg>
							</template>
							<template v-slot:cell(challenged)="data">
								<figure
									class="match-player team-2"
									role="group"
									style="justify-content: flex-end"
								>
									<figure
										class="match-player__avatar"
										style="order: 2"
									>
										<img
											:src="
												data.item.players[1].avatar
											"
											:srcset="
												data.item.players[1].avatar
											"
											alt=""
										/>
									</figure>
									<figcaption class="mr-2">
										<span
											class="match-player__nickname"
											style="font-size: 1.2rem"
											>{{
												data.item.players[1].name
											}}</span
										>
										<span v-if="data.item.players[0].character"
											class="match-player__name"
											style="font-size: 0.8rem"
											>{{
												data.item.players[1].character.name
											}}</span
										>
									</figcaption>
								</figure>
							</template>
							<template v-slot:empty>
								<h5 class="color-primary text-center my-auto">
									NO RECORDS FOUND
								</h5>
							</template>
						</b-table>
					</div>
				</div>
			</div>
			<div class="mt-4">
				<b-pagination
					v-model="currentPage"
					:total-rows="rows"
					:per-page="perPage"
					size="lg"
					align="center"
				>
				</b-pagination>
			</div>
		</div>
	</main>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'Challenges',
	mounted() {
		// remove default bootstrap vue classes
		const el = document.getElementById('challengeTable');
		if (el) el.classList.remove('table', 'b-table');
	},
	data() {
		return {
			fields: [
				{
					key: 'challenger',
					label: 'Player',
				},
				{
					key: 'challengerResult',
					label: 'Result',
				},
				// {
				// 	key: 'game',
				// 	label: 'Game',
				// },
				{
					key: 'actions',
					tdClass: 'td-hero td-d-none-sm td-center',
					label: '',
				},
				{
					key: 'challengedResult',
					label: 'Result',
					tdClass: 'td-hero',
				},
				{
					key: 'challenged',
					label: 'Player',
					// tdClass: 'challenged',
				},
				// {
				// 	key: 'gameId',
				// 	label: 'game',
				// },
				// {
				// 	key: 'createdOn',
				// 	label: 'Created',
				// 	tdClass: 'td-hero',
				// },
			],
			isBusy: true,
			challengeType: 'active',
			currentPage: 1,
			perPage: 5,
		};
	},
	watch: {
		$route(to) {
			const { type } = to.params;
			this.setType(type);
		},
	},
	computed: {
		...mapGetters({
			challenges: 'getChallenges',
			activeChallenges: 'activeChallenges',
			sentChallenges: 'sentChallenges',
			receivedChallenges: 'receivedChallenges',
		}),
		items() {
			let response = this.activeChallenges;
			if (this.challengeType === 'all') {
				response = this.challenges;
			}
			if (this.challengeType === 'received') {
				response = this.receivedChallenges;
			}
			if (this.challengeType === 'sent') {
				response = this.sentChallenges;
			}
			return response;
		},
		rows() {
			return this.items.length;
		},
	},
	methods: {
		setType(type) {
			this.challengeType = type;
		},
		goToActiveChallenge(data) {
			this.$router.push({
				name: 'MatchUp',
				params: { id: data.item.id },
			});
		},
		viewChallengeDetails(data) {
			this.$router.push({
				name: 'MatchResult',
				params: { id: data.item.gameMatchId, challengeId: data.item.id },
			});
		},
	},
};
</script>

<style scoped>
.challenged {
	text-align: right;
	padding-right: 0 !important;
	width: 250px;
}
.match-player__avatar {
	width: 64px;
	height: 64px;
}
</style>
